.marker-modal {
  .gender {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 32px;
    color: var(--ion-color-dark);
    opacity: .5;
  }

  .buttons-grid {
    margin-top: 5px;
    display: flex;

    ion-button {
      flex: 1 1 auto;
    }
  }
}
