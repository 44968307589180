#map,
.leaflet-container {
  height: 100%;
  width: 100%;

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-div-icon {
    width: 50px;
    height: 50px;
    background: transparent;
    border: 0;
  }

  .custom-marker {
    background: red;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.35));
    transition: all 0.2s ease;

    &.selected {
      filter: drop-shadow(0 0 17px rgba(0, 0, 0, 0.45));
      border-color: var(--ion-color-primary);
      color: var(--ion-color-primary);
      transition: all 0.2s ease;
    }

    &.red {
      background: var(--ion-color-danger);
    }

    &.yellow {
      background: var(--ion-color-warning);
    }

    &.orange {
      background: var(--ion-color-orange);
    }

    &.green {
      background: var(--ion-color-success);
    }
  }
}
/*
.sensor-logs-modal {
    margin-top: 20px;
    height: 80vh;
    color: var(--ion-color-dark);

    .no-logs {
        padding-top: 20px;
        font-size: large;
        text-align: center;
    }

    .recharts-tooltip-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(var(--ion-color-medium-rgb), 0.3);
    }
}
*/
