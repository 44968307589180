#map_history {
  height: 100%;
  width: 100%;

  .custom-marker {
    background: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid var(--ion-color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.35));
    transition: all 0.2s ease;

    &.selected {
      background: var(--ion-color-warning);
      width: 25px;
      height: 25px;
    }
  }

  .leaflet-control-attribution {
    display: none;
  }
}

.timeline {
  h3 {
    color: var(--ion-color-dark);
    text-align: left;
    font-size: 28px;
    font-weight: 600;
  }

  .log-details {
    ion-card {
      margin: 0;

      ion-card-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        ion-icon {
          font-size: 32px;
          margin-bottom: 10px;
        }

        span {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }

        &.time {
          ion-icon {
            color: var(--ion-color-success);
          }
        }

        &.battery {
          ion-icon {
            color: var(--ion-color-warning);
          }
        }

        &.location {
          ion-icon {
            color: var(--ion-color-danger);
          }
        }
      }
    }
  }
}
