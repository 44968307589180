.tutorial {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.87);
  z-index: 999999999;
  color: #fff;
  padding: 0 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;
    opacity: 0.8;
    line-height: 25px;
    margin: 15px 0 0;
  }
}
