/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /*--ion-background-color: #fff;
    --ion-text-color: var(--ion-color-dark);
    --ion-border-color: var(--ion-color-medium);
    --ion-item-border-color: var(--ion-color-medium);*/

	--ion-color-primary: #105157;
	--ion-color-primary-rgb: 16,81,87;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0e474d;
	--ion-color-primary-tint: #286268;

	--ion-color-secondary: #d1d1d1;
	--ion-color-secondary-rgb: 209,209,209;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #b8b8b8;
	--ion-color-secondary-tint: #d6d6d6;

	--ion-color-tertiary: #35bddb;
	--ion-color-tertiary-rgb: 53,189,219;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #2fa6c1;
	--ion-color-tertiary-tint: #49c4df;

	--ion-color-success: #bade6a;
	--ion-color-success-rgb: 186,222,106;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #a4c35d;
	--ion-color-success-tint: #c1e179;

	--ion-color-warning: #dec56a;
	--ion-color-warning-rgb: 222,197,106;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #c3ad5d;
	--ion-color-warning-tint: #e1cb79;

	--ion-color-danger: #de7588;
	--ion-color-danger-rgb: 222,117,136;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #c36778;
	--ion-color-danger-tint: #e18394;

	--ion-color-medium: #a5b2b4;
	--ion-color-medium-rgb: 165,178,180;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #919d9e;
	--ion-color-medium-tint: #aebabc;

	--ion-color-light: #fff;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

	--ion-color-orange: #ff9a83;
	--ion-color-orange-rgb: 255,154,131;
	--ion-color-orange-contrast: #000000;
	--ion-color-orange-contrast-rgb: 0,0,0;
	--ion-color-orange-shade: #e08873;
	--ion-color-orange-tint: #ffa48f;
}
