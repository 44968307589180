.container {
  padding: 0 15px;
}
/*
.header-md::after {
    display: none !important;
}

ion-header {
    box-shadow: none !important;
}
*/

.addNewDeviceFab {
  bottom: 60px;
}

ion-button {
  --box-shadow: none;
  font-weight: 600;
  text-transform: uppercase;
}

.modal-title {
  padding: 10px 0 10px;
  color: var(--ion-color-dark);
  text-align: left;
  font-size: 28px;
  font-weight: 600;
}

.rounded-input {
  position: relative;
  --border-color: transparent;
  --color: var(--ion-color-primary);
  --border-radius: 10px;
  --border-width: 1px;
  --highlight-height: 0;
  --background: rgba(var(--ion-color-primary-rgb), 0.15);
  --min-height: 45px;
  margin: 35px 0 15px;
  padding: 0 0 10px;
  overflow: initial;
  font-size: 16px !important;

  &.ion-invalid {
   --background: rgba(var(--ion-color-danger-rgb), 0.15);
   --color: var(--ion-color-danger);
  }

  ion-label {
    position: absolute;
    top: -38px;
    left: -15px;
    transform: none !important;
    font-size: 15px !important;
  }

  /*
    &.item-has-value,
    &.item-has-focus {
        --color: var(--ion-color-primary);

        ion-label {
            color: var(--ion-color-secondary);
        }
    }*/

  &.error {
    --border-color: var(--ion-color-danger);
    --color: var(--ion-color-danger);
  }
}
