ion-header {
  ion-toolbar {
    ion-title {
      font-weight: 800;
      // padding-inline: 15px;
      // text-align: left;
      font-size: 20px;
    }
  }
}
