.devices-list {
  ion-card {
    ion-card-header {
      padding-bottom: 5px;

      ion-card-title {
        margin-bottom: 5px;
      }

      .gender {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 32px;
        color: var(--ion-color-dark);
        opacity: .5;
      }
    }
  }

  .buttons-grid {
    display: flex;

    ion-button {
      flex: 1 1 auto;
    }
  }
}
