.swiper {
  height: 100%;

  .swiper-slide {
  }
}

.intro-slides {
  .intro-slide {
    align-items: flex-end;

    &-1 {
      background: #fff url('../assets/intro/1.jpg') 50% 50% no-repeat;
      background-size: cover;
    }

    &-2 {
      background: #fff url('../assets/intro/2.jpg') 50% 50% no-repeat;
      background-size: cover;
    }

    &-3 {
      background: #fff url('../assets/intro/3.jpg') 50% 50% no-repeat;
      background-size: cover;
    }

    .details {
      background: #fff;
      padding: 30px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: left;

      h1 {
        font-size: 21px;
        font-weight: 700;
        margin: 0;
      }

      p {
        font-size: 16px;
        opacity: 0.8;
        line-height: 24px;
        margin: 25px 0 30px;
      }
    }
  }
}
